.footer {
  background-color: $btn-color;
  margin: 76px 0 0 0;
  height: 692px;

  .footer__wrap {

    .footer__title {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      h2 {
        font-size: 32px;
        line-height: 32px;
        font-weight: 700;
        letter-spacing: 0.1px;
        color: $white;
        margin-top: 76px;
        margin-bottom: 17px;
      }

      p {
        font-size: 20px;
        line-height: 24px;
        font-weight: 400;
        color: #898989;
        margin-bottom: 40px;
      }
    }

    .footer__form {
      display: flex;
      flex-direction: row;
      justify-content: center;

      input {
        width: 70%;
        height: 51px;
        background-color: $white;
        padding: 0 24px;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        color: #898989;
      }

      .footer__btn {
        $values: (fs: 14px, lh: 24px, ls: 0.6px, w: 135px, br: 0, bg: $white, clr: $btn-color);
        @include btn($values...) {
          border: 2px solid $dark-black;
          height: 58px;
          padding: 10px 0;
          font-weight: 700;
          text-transform: uppercase;
          cursor: pointer;
          margin-top: -1px;
          margin-left: 6px;
        };

        &:hover {
          background-color: gainsboro;
          color: blue;
        }
      }

    }

    .footer__blocks {
      margin: 114px 0 0 0;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .footer__element {
        li a, li {
          text-decoration: none;
          font-size: 16px;
          line-height: 20px;
          font-weight: 400;
          color: gainsboro;
        }

        li a:hover {
          color: $bg-color;
        }

        .email {
          margin: 8px 0;
        }
      }
    }

  }

}

@media (max-width: 776px) {
  .footer {
    margin-top: 100px;
    .footer__wrap {
      .footer__title {
        h2 {
          font-size: 26px;
        }

        p {
          font-size: 16px;
        }
      }
      .footer__blocks {
        margin: 30px 0 0 0;
        justify-content: center;
        .footer__element {
          padding: 15px 15px;
        }
      }
    }
  }
}

@media (max-width: 382px) {
  .footer {
    .footer__wrap {
      .footer__blocks {
        .footer__element {
          li a, li {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media (max-width: 351px) {
  .footer {
    .footer__wrap {
      .footer__blocks {
        .footer__element {
          li a, li {
            font-size: 11px;
          }
        }
      }
    }
  }
}

