.contacts {
  margin-top: 134px;

  .contacts__wrap {

    .contacts__title {

      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      h2 {
        font-size: 32px;
        line-height: 32px;
        font-weight: 700;
        letter-spacing: 0.1px;
        color: $btn-color;
        margin-bottom: 17px;
      }

      p {
        font-size: 20px;
        line-height: 24px;
        font-weight: 400;
        color: #898989;
        margin-bottom: 50px;
      }

    }

    .contacts__blocks {
      max-width: 600px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0 auto;

      .contacts__element {
        flex-direction: column;
        justify-content: center;
        text-align: center;
        font-size: 20px;

        h2 {
          margin-top: 25px;
          font-size: 24px;
          line-height: 24px;
          font-weight: 400;
          color: $btn-color;
        }

        p {
          width: 182px;
          font-size: 14px;
          line-height: 16px;
          font-weight: 400;
          color: #898989;
          margin-top: 5px;
        }

      }
    }

    .contacts__form {
      display: flex;
      flex-direction: column;
      align-items: center;

      input {
        width: 48%;
        height: 42px;
        background-color: $white;
        border: 2.0px solid $dark-black;
        margin: 25px 0;
        padding: 0 20px;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        color: #898989;

        &:first-child {
          margin: 43px 0 0 0;
        }
      }

      textarea {
        resize: none;
        width: 48%;
        height: 200px;
        background-color: $white;
        border: 2.0px solid $dark-black;
        padding: 20px 20px 0 20px;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        color: #898989;
      }

      .contacts__btn {
          $values: (fs: 14px, lh: 24px, ls: 0.6px, w: 170px, br: 0, bg: $btn-color, clr: $white);
          @include btn($values...) {
            border: 2px solid $dark-black;
            height: 51px;
            padding: 10px 0;
            font-weight: 700;
            text-transform: uppercase;
            cursor: pointer;
            margin-top: 24px;
          };
      }
    }

  }
}

@media (max-width: 950px) {
  .contacts {
    .contacts__wrap {
      .contacts__blocks {
        justify-content: center;
        .contacts__element {
          padding: 0 15px 15px 15px;
        }
      }
    }
  }
}

@media (max-width: 776px) {
  .contacts {
    margin-top: 100px;
    .contacts__wrap {
      .contacts__title {
        h2 {
          font-size: 26px;
        }

        p {
          font-size: 16px;
        }
      }
    }
  }
}

@media (max-width: 660px) {
  .contacts {
    .contacts__wrap {
      .contacts__form {
        input, textarea {
          width: 90%;
        }
      }
    }
  }
}



