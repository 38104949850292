.works {
  margin-top: 196px;


  .works__wrap {
    padding: 0;

    .works__title {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      h2 {
        font-size: 32px;
        line-height: 32px;
        font-weight: 700;
        letter-spacing: 0.1px;
        color: $btn-color;
        margin-bottom: 17px;
      }

      p {
        font-size: 20px;
        line-height: 24px;
        font-weight: 400;
        color: #898989;
        margin-bottom: 70px;
      }
    }

    .works__blocks {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .works__full-element {
        display: flex;
        flex-direction: column;

        .works__element {
          position: relative;
          flex-basis: 23.4%;

          .works__add {
            position: absolute;
            top: 88px;
            left: 88px;
            width: 48px;
            height: 48px;
            background-color: $white;
            border-radius: 44px;
            display: none;
          }


          &:hover .works__img {
            filter: brightness(50%);
          }

          &:hover .works__add {
            display: flex;
            filter: brightness(100%);
          }

          .works__add:hover {
            filter: brightness(70%);
          }


          .works__items {
            position: absolute;
            display: flex;
            justify-content: space-between;
            width: 200px;
            padding: 0 10px;
            bottom: 14px;
            align-items: center;

            .works__items--left {
              display: flex;
              align-items: center;

              .left__margin {
                margin-right: 5px;
                font-size: 22px;
                line-height: 18px;
                font-weight: 400;
                color: $white;
              }

              p {
                font-size: 14px;
                line-height: 18px;
                font-weight: 400;
                color: $white;
              }
            }

            .works__items--right {
              display: flex;
              align-items: center;

              .right__margin {
                margin-left: 5px;
                font-size: 22px;
                line-height: 18px;
                font-weight: 400;
                color: $white;
              }

              p {
                font-size: 14px;
                line-height: 18px;
                font-weight: 400;
                color: $white;
              }
            }
          }
        }

        .works__text {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          h2 {
            margin: 21px 0 10px 0;
            font-size: 22px;
            line-height: 24px;
            font-weight: 700;
            color: $btn-color;
          }

          p {
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
            color: #898989;
            margin-bottom: 65px;
          }
        }
      }


    }
  }

}

@media (max-width: 950px) {
  .works {
    .works__wrap {
      .works__blocks {
        justify-content: center;

        .works__full-element {
          .works__element {
            padding: 0 15px;
            .works__add {
              left: 100px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 776px) {
  .works {
    margin-top: 100px;

    .works__wrap {
      .works__title {
        h2 {
          font-size: 26px;
        }

        p {
          font-size: 16px;
        }
      }
    }
  }
}


