/**
*Основные стили
**/

body {
  font-family: 'SourceSansPro', sans-serif;
  font-size: 16px;
}

.container {
  @include min-max(320px, 100%, 0);
}

.wrap {
  @include min-max(320px, 935px, 30px);
}

p {
  color: $black;
}