@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700&display=swap&subset=cyrillic");
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: '';
  content: none; }

q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/**
*Основные стили
**/
body {
  font-family: 'SourceSansPro', sans-serif;
  font-size: 16px; }

.container {
  min-width: 320px;
  max-width: 100%;
  padding: 0 0;
  box-sizing: border-box;
  margin: 0 auto; }

.wrap {
  min-width: 320px;
  max-width: 935px;
  padding: 0 30px;
  box-sizing: border-box;
  margin: 0 auto; }

p {
  color: #222222; }

.header {
  height: 860px;
  background-color: #fcdb00;
  color: #222222; }
  .header .menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 60px; }
  .header .burger {
    width: 19px;
    height: 16px;
    font-size: 22px;
    color: #222222; }
    .header .burger:hover {
      color: crimson; }
  .header .logo {
    flex-basis: 17%;
    padding-left: 4%; }
  .header .logo a {
    display: flex;
    align-items: center;
    text-decoration: none; }
  .header .logo__title {
    margin-left: 10px;
    font-size: 30px;
    line-height: 20px;
    font-weight: 700;
    color: #222222; }
  .header .social__block {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-basis: 12%; }
    .header .social__block .social__element {
      margin-left: 16px;
      font-size: 21px; }
      .header .social__block .social__element:first-child {
        margin-left: 0; }
      .header .social__block .social__element a:hover {
        color: crimson; }
    .header .social__block .social__element a {
      color: #222222; }
  .header .header__info {
    max-width: 536px;
    display: flex;
    height: 693px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto; }
    .header .header__info h1 {
      font-size: 48px;
      line-height: 1;
      font-weight: 700;
      text-align: center; }
    .header .header__info p {
      max-width: 536px;
      font-size: 20px;
      line-height: 24px;
      font-weight: 400;
      padding-top: 22px;
      padding-bottom: 41px;
      text-align: center; }
    .header .header__info .header__btn {
      width: 256px;
      color: #010101;
      font-size: 22px;
      line-height: 24px;
      letter-spacing: 0.6px;
      background-color: transparent;
      border-radius: 0;
      padding: 13px 0;
      display: block;
      text-align: center;
      text-transform: uppercase;
      text-decoration: none;
      border: 2px solid #000000;
      padding: 18px 0;
      font-weight: 700;
      text-transform: uppercase; }
      .header .header__info .header__btn:hover {
        background-color: #222222;
        color: #fcdb00; }
  .header .header__wrap .arrow {
    display: flex;
    justify-content: center; }
    .header .header__wrap .arrow a {
      font-size: 40px;
      line-height: 24px;
      font-weight: 400;
      color: #222222;
      text-decoration: none; }
      .header .header__wrap .arrow a:hover {
        color: crimson; }

@media (max-width: 440px) {
  .header__wrap .arrow {
    margin: -40px; } }

@media (max-width: 400px) {
  .menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; }
    .menu .social__block {
      margin-top: 15px;
      flex-basis: 100%;
      justify-content: center; } }

.features {
  padding-top: 150px;
  margin-bottom: 220px; }
  .features .features_wrap .features__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center; }
    .features .features_wrap .features__title h2 {
      font-size: 32px;
      line-height: 32px;
      font-weight: 700;
      letter-spacing: 0.1px;
      color: #010101;
      margin-bottom: 17px; }
    .features .features_wrap .features__title p {
      font-size: 20px;
      line-height: 24px;
      font-weight: 400;
      color: #898989;
      margin-bottom: 91px; }
  .features .features_wrap .features__blocks {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 309px; }
    .features .features_wrap .features__blocks .features__element {
      flex-basis: 32%;
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
      .features .features_wrap .features__blocks .features__element .features__text {
        width: 80%; }
        .features .features_wrap .features__blocks .features__element .features__text h2 {
          font-size: 22px;
          line-height: 24px;
          font-weight: 700;
          color: #010101;
          margin-bottom: 14px; }
        .features .features_wrap .features__blocks .features__element .features__text p {
          font-size: 16px;
          line-height: 20px;
          font-weight: 400;
          color: #898989; }
      .features .features_wrap .features__blocks .features__element img {
        width: 14%;
        height: 23%; }
      .features .features_wrap .features__blocks .features__element:first-child img, .features .features_wrap .features__blocks .features__element:last-child img {
        width: 11%; }

@media (max-width: 776px) {
  .features {
    padding-top: 100px; }
    .features .features_wrap .features__title h2 {
      font-size: 26px; }
    .features .features_wrap .features__title p {
      font-size: 16px; }
    .features .features_wrap .features__blocks .features__element {
      flex-basis: 100%;
      padding: 15px 15px; }
      .features .features_wrap .features__blocks .features__element img {
        width: 44px;
        height: 44px; }
      .features .features_wrap .features__blocks .features__element:first-child img, .features .features_wrap .features__blocks .features__element:last-child img {
        width: 44px; } }

@media (max-width: 440px) {
  .features .features_wrap .features__title {
    padding: 10px 0; }
    .features .features_wrap .features__title p {
      margin-bottom: 20px; }
  .features .features_wrap .features__blocks .features__element img {
    width: 36px;
    height: 36px; }
  .features .features_wrap .features__blocks .features__element:first-child img, .features .features_wrap .features__blocks .features__element:last-child img {
    width: 36px; } }

.trial .trial__wrap {
  max-width: 714px;
  padding: 0; }
  .trial .trial__wrap .trial__title {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column; }
    .trial .trial__wrap .trial__title h2 {
      font-size: 32px;
      line-height: 32px;
      font-weight: 700;
      color: #010101;
      margin-bottom: 17px; }
    .trial .trial__wrap .trial__title p {
      width: 762px;
      font-size: 20px;
      line-height: 24px;
      font-weight: 400;
      color: #898989;
      margin-bottom: 77px; }
  .trial .trial__wrap .trial__blocks {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap; }
    .trial .trial__wrap .trial__blocks .trial__element {
      flex-basis: 32%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .trial .trial__wrap .trial__blocks .trial__element .top__h2 {
        margin-top: 23px;
        font-size: 24px;
        line-height: 24px;
        font-weight: 700;
        color: #010101;
        letter-spacing: 0.1px; }
      .trial .trial__wrap .trial__blocks .trial__element .top__p {
        margin-top: 26px;
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        color: #898989;
        text-align: center; }
      .trial .trial__wrap .trial__blocks .trial__element .bottom__h2 {
        margin-top: 45px;
        font-size: 32px;
        line-height: 24px;
        font-weight: 700;
        text-transform: uppercase;
        color: #010101; }
      .trial .trial__wrap .trial__blocks .trial__element .border {
        width: 50px;
        height: 2px;
        background-color: #010101;
        margin: 12px 0; }
      .trial .trial__wrap .trial__blocks .trial__element .bottom__p {
        font-size: 16px;
        line-height: 48px;
        font-weight: 400;
        color: #898989; }
      .trial .trial__wrap .trial__blocks .trial__element .trial__btn {
        width: 142px;
        color: #010101;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0;
        background-color: transparent;
        border-radius: 0;
        padding: 13px 0;
        display: block;
        text-align: center;
        text-transform: uppercase;
        text-decoration: none;
        border: 2px solid #000000;
        padding: 12px 0;
        font-weight: 700;
        text-transform: uppercase; }
        .trial .trial__wrap .trial__blocks .trial__element .trial__btn:hover {
          background-color: #222222;
          color: #fcdb00; }

@media (max-width: 776px) {
  .trial {
    margin-top: 390px; }
    .trial .trial__wrap .trial__title p {
      width: 100%;
      padding: 0 15px;
      font-size: 16px; }
    .trial .trial__wrap .trial__title h2 {
      font-size: 26px; } }

@media (max-width: 570px) {
  .trial {
    margin-top: 560px; } }

@media (max-width: 445px) {
  .trial {
    margin-top: 625px; }
    .trial .trial__wrap .trial__blocks .trial__element {
      flex-basis: 100%; }
      .trial .trial__wrap .trial__blocks .trial__element .trial__btn {
        width: 90%;
        margin: 0 5px; }
      .trial .trial__wrap .trial__blocks .trial__element img {
        display: none; } }

@media (max-width: 445px) {
  .trial {
    margin-top: 736px; } }

@media (max-width: 336px) {
  .trial {
    margin-top: 780px; }
    .trial .trial__wrap .trial__title h2 {
      font-size: 1.5em; }
    .trial .trial__wrap .trial__title p {
      font-size: 1em; } }

.works {
  margin-top: 196px; }
  .works .works__wrap {
    padding: 0; }
    .works .works__wrap .works__title {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center; }
      .works .works__wrap .works__title h2 {
        font-size: 32px;
        line-height: 32px;
        font-weight: 700;
        letter-spacing: 0.1px;
        color: #010101;
        margin-bottom: 17px; }
      .works .works__wrap .works__title p {
        font-size: 20px;
        line-height: 24px;
        font-weight: 400;
        color: #898989;
        margin-bottom: 70px; }
    .works .works__wrap .works__blocks {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      .works .works__wrap .works__blocks .works__full-element {
        display: flex;
        flex-direction: column; }
        .works .works__wrap .works__blocks .works__full-element .works__element {
          position: relative;
          flex-basis: 23.4%; }
          .works .works__wrap .works__blocks .works__full-element .works__element .works__add {
            position: absolute;
            top: 88px;
            left: 88px;
            width: 48px;
            height: 48px;
            background-color: white;
            border-radius: 44px;
            display: none; }
          .works .works__wrap .works__blocks .works__full-element .works__element:hover .works__img {
            filter: brightness(50%); }
          .works .works__wrap .works__blocks .works__full-element .works__element:hover .works__add {
            display: flex;
            filter: brightness(100%); }
          .works .works__wrap .works__blocks .works__full-element .works__element .works__add:hover {
            filter: brightness(70%); }
          .works .works__wrap .works__blocks .works__full-element .works__element .works__items {
            position: absolute;
            display: flex;
            justify-content: space-between;
            width: 200px;
            padding: 0 10px;
            bottom: 14px;
            align-items: center; }
            .works .works__wrap .works__blocks .works__full-element .works__element .works__items .works__items--left {
              display: flex;
              align-items: center; }
              .works .works__wrap .works__blocks .works__full-element .works__element .works__items .works__items--left .left__margin {
                margin-right: 5px;
                font-size: 22px;
                line-height: 18px;
                font-weight: 400;
                color: white; }
              .works .works__wrap .works__blocks .works__full-element .works__element .works__items .works__items--left p {
                font-size: 14px;
                line-height: 18px;
                font-weight: 400;
                color: white; }
            .works .works__wrap .works__blocks .works__full-element .works__element .works__items .works__items--right {
              display: flex;
              align-items: center; }
              .works .works__wrap .works__blocks .works__full-element .works__element .works__items .works__items--right .right__margin {
                margin-left: 5px;
                font-size: 22px;
                line-height: 18px;
                font-weight: 400;
                color: white; }
              .works .works__wrap .works__blocks .works__full-element .works__element .works__items .works__items--right p {
                font-size: 14px;
                line-height: 18px;
                font-weight: 400;
                color: white; }
        .works .works__wrap .works__blocks .works__full-element .works__text {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center; }
          .works .works__wrap .works__blocks .works__full-element .works__text h2 {
            margin: 21px 0 10px 0;
            font-size: 22px;
            line-height: 24px;
            font-weight: 700;
            color: #010101; }
          .works .works__wrap .works__blocks .works__full-element .works__text p {
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
            color: #898989;
            margin-bottom: 65px; }

@media (max-width: 950px) {
  .works .works__wrap .works__blocks {
    justify-content: center; }
    .works .works__wrap .works__blocks .works__full-element .works__element {
      padding: 0 15px; }
      .works .works__wrap .works__blocks .works__full-element .works__element .works__add {
        left: 100px; } }

@media (max-width: 776px) {
  .works {
    margin-top: 100px; }
    .works .works__wrap .works__title h2 {
      font-size: 26px; }
    .works .works__wrap .works__title p {
      font-size: 16px; } }

.team {
  margin-top: 200px; }
  .team .team__wrap {
    padding: 0; }
    .team .team__wrap .team__title {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center; }
      .team .team__wrap .team__title h2 {
        font-size: 32px;
        line-height: 32px;
        font-weight: 700;
        letter-spacing: 0.1px;
        color: #010101;
        margin-bottom: 17px; }
      .team .team__wrap .team__title p {
        font-size: 20px;
        line-height: 24px;
        font-weight: 400;
        color: #898989;
        margin-bottom: 76px; }
    .team .team__wrap .team__blocks {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap; }
      .team .team__wrap .team__blocks .team__element {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-basis: 14%; }
        .team .team__wrap .team__blocks .team__element h2 {
          font-size: 22px;
          line-height: 24px;
          font-weight: 400;
          color: #010101;
          margin: 42px 0 12px 0; }
        .team .team__wrap .team__blocks .team__element p {
          font-size: 16px;
          line-height: 20px;
          font-weight: 400;
          color: #898989;
          padding-bottom: 16px; }
        .team .team__wrap .team__blocks .team__element .p__bottom {
          text-align: center;
          width: 195px;
          font-size: 14px;
          line-height: 18px;
          font-weight: 400;
          color: #313841;
          padding-bottom: 14px; }
        .team .team__wrap .team__blocks .team__element .social__team {
          width: 63px;
          display: flex;
          justify-content: space-around; }
          .team .team__wrap .team__blocks .team__element .social__team a {
            text-decoration: none;
            color: #010101; }
            .team .team__wrap .team__blocks .team__element .social__team a:hover {
              color: red; }

@media (max-width: 950px) {
  .team {
    margin-top: 50px; }
    .team .team__wrap .team__blocks {
      justify-content: center; }
      .team .team__wrap .team__blocks .team__element {
        padding: 0 15px 15px 15px; } }

@media (max-width: 776px) {
  .team .team__wrap .team__title h2 {
    font-size: 26px; }
  .team .team__wrap .team__title p {
    font-size: 16px; } }

.stats {
  margin-top: 190px; }
  .stats .stats__wrap .stats__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center; }
    .stats .stats__wrap .stats__title h2 {
      font-size: 32px;
      line-height: 32px;
      font-weight: 700;
      letter-spacing: 0.1px;
      color: #010101;
      margin-bottom: 17px; }
    .stats .stats__wrap .stats__title p {
      font-size: 20px;
      line-height: 24px;
      font-weight: 400;
      color: #898989;
      margin-bottom: 52px; }
  .stats .stats__wrap .stats__blocks {
    display: flex;
    justify-content: space-between;
    max-width: 560px;
    margin: 0 auto;
    align-items: flex-end;
    flex-wrap: wrap; }
    .stats .stats__wrap .stats__blocks .stats__element {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .stats .stats__wrap .stats__blocks .stats__element img {
        margin-bottom: 22px; }
        .stats .stats__wrap .stats__blocks .stats__element img:first-child {
          padding-left: 8px; }
      .stats .stats__wrap .stats__blocks .stats__element h2 {
        font-size: 24px;
        line-height: 24px;
        font-weight: 700;
        color: #010101; }
      .stats .stats__wrap .stats__blocks .stats__element p {
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
        color: #898989; }
  .stats .stats__wrap .stats__team {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 80px;
    align-items: center; }
    .stats .stats__wrap .stats__team .stats__team--element {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin: 0 auto;
      text-align: center; }
      .stats .stats__wrap .stats__team .stats__team--element img {
        width: 104px;
        height: 104px;
        margin-left: 8px; }
      .stats .stats__wrap .stats__team .stats__team--element h2 {
        font-size: 22px;
        line-height: 24px;
        font-weight: 400;
        color: #010101;
        margin: 18px 0 12px 0; }
      .stats .stats__wrap .stats__team .stats__team--element p {
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        color: #898989;
        margin-bottom: 31px; }
    .stats .stats__wrap .stats__team .stats__quote {
      display: flex; }
      .stats .stats__wrap .stats__team .stats__quote p {
        width: 508px;
        font-size: 20px;
        line-height: 24px;
        font-weight: 400;
        color: #898989;
        text-align: center; }
      .stats .stats__wrap .stats__team .stats__quote::before {
        content: url("../img/stats_quote.png");
        margin-right: 56px; }
      .stats .stats__wrap .stats__team .stats__quote::after {
        content: url("../img/stats_quote.png");
        margin-left: 45px;
        transform: rotate(180deg); }

@media (max-width: 950px) {
  .stats .stats__wrap .stats__blocks {
    justify-content: center; }
    .stats .stats__wrap .stats__blocks .stats__element {
      padding: 0 15px; } }

@media (max-width: 776px) {
  .stats {
    margin-top: 100px; }
    .stats .stats__wrap .stats__title h2 {
      font-size: 26px; }
    .stats .stats__wrap .stats__title p {
      font-size: 16px; }
    .stats .stats__wrap .stats__team .stats__quote {
      width: 100%; }
      .stats .stats__wrap .stats__team .stats__quote p {
        font-size: 16px; } }

@media (max-width: 590px) {
  .stats .stats__wrap .stats__blocks .stats__element {
    padding-top: 15px; }
  .stats .stats__wrap .stats__team {
    margin: 80px 15px; }
    .stats .stats__wrap .stats__team .stats__quote {
      width: 100%; }
      .stats .stats__wrap .stats__team .stats__quote::before, .stats .stats__wrap .stats__team .stats__quote::after {
        display: none; } }

.contacts {
  margin-top: 134px; }
  .contacts .contacts__wrap .contacts__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center; }
    .contacts .contacts__wrap .contacts__title h2 {
      font-size: 32px;
      line-height: 32px;
      font-weight: 700;
      letter-spacing: 0.1px;
      color: #010101;
      margin-bottom: 17px; }
    .contacts .contacts__wrap .contacts__title p {
      font-size: 20px;
      line-height: 24px;
      font-weight: 400;
      color: #898989;
      margin-bottom: 50px; }
  .contacts .contacts__wrap .contacts__blocks {
    max-width: 600px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto; }
    .contacts .contacts__wrap .contacts__blocks .contacts__element {
      flex-direction: column;
      justify-content: center;
      text-align: center;
      font-size: 20px; }
      .contacts .contacts__wrap .contacts__blocks .contacts__element h2 {
        margin-top: 25px;
        font-size: 24px;
        line-height: 24px;
        font-weight: 400;
        color: #010101; }
      .contacts .contacts__wrap .contacts__blocks .contacts__element p {
        width: 182px;
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
        color: #898989;
        margin-top: 5px; }
  .contacts .contacts__wrap .contacts__form {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .contacts .contacts__wrap .contacts__form input {
      width: 48%;
      height: 42px;
      background-color: white;
      border: 2px solid #000000;
      margin: 25px 0;
      padding: 0 20px;
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      color: #898989; }
      .contacts .contacts__wrap .contacts__form input:first-child {
        margin: 43px 0 0 0; }
    .contacts .contacts__wrap .contacts__form textarea {
      resize: none;
      width: 48%;
      height: 200px;
      background-color: white;
      border: 2px solid #000000;
      padding: 20px 20px 0 20px;
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      color: #898989; }
    .contacts .contacts__wrap .contacts__form .contacts__btn {
      width: 170px;
      color: white;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.6px;
      background-color: #010101;
      border-radius: 0;
      padding: 13px 0;
      display: block;
      text-align: center;
      text-transform: uppercase;
      text-decoration: none;
      border: 2px solid #000000;
      height: 51px;
      padding: 10px 0;
      font-weight: 700;
      text-transform: uppercase;
      cursor: pointer;
      margin-top: 24px; }
      .contacts .contacts__wrap .contacts__form .contacts__btn:hover {
        background-color: #222222;
        color: #fcdb00; }

@media (max-width: 950px) {
  .contacts .contacts__wrap .contacts__blocks {
    justify-content: center; }
    .contacts .contacts__wrap .contacts__blocks .contacts__element {
      padding: 0 15px 15px 15px; } }

@media (max-width: 776px) {
  .contacts {
    margin-top: 100px; }
    .contacts .contacts__wrap .contacts__title h2 {
      font-size: 26px; }
    .contacts .contacts__wrap .contacts__title p {
      font-size: 16px; } }

@media (max-width: 660px) {
  .contacts .contacts__wrap .contacts__form input, .contacts .contacts__wrap .contacts__form textarea {
    width: 90%; } }

.footer {
  background-color: #010101;
  margin: 76px 0 0 0;
  height: 692px; }
  .footer .footer__wrap .footer__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center; }
    .footer .footer__wrap .footer__title h2 {
      font-size: 32px;
      line-height: 32px;
      font-weight: 700;
      letter-spacing: 0.1px;
      color: white;
      margin-top: 76px;
      margin-bottom: 17px; }
    .footer .footer__wrap .footer__title p {
      font-size: 20px;
      line-height: 24px;
      font-weight: 400;
      color: #898989;
      margin-bottom: 40px; }
  .footer .footer__wrap .footer__form {
    display: flex;
    flex-direction: row;
    justify-content: center; }
    .footer .footer__wrap .footer__form input {
      width: 70%;
      height: 51px;
      background-color: white;
      padding: 0 24px;
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      color: #898989; }
    .footer .footer__wrap .footer__form .footer__btn {
      width: 135px;
      color: #010101;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.6px;
      background-color: white;
      border-radius: 0;
      padding: 13px 0;
      display: block;
      text-align: center;
      text-transform: uppercase;
      text-decoration: none;
      border: 2px solid #000000;
      height: 58px;
      padding: 10px 0;
      font-weight: 700;
      text-transform: uppercase;
      cursor: pointer;
      margin-top: -1px;
      margin-left: 6px; }
      .footer .footer__wrap .footer__form .footer__btn:hover {
        background-color: #222222;
        color: #fcdb00; }
      .footer .footer__wrap .footer__form .footer__btn:hover {
        background-color: gainsboro;
        color: blue; }
  .footer .footer__wrap .footer__blocks {
    margin: 114px 0 0 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    .footer .footer__wrap .footer__blocks .footer__element li a, .footer .footer__wrap .footer__blocks .footer__element li {
      text-decoration: none;
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      color: gainsboro; }
    .footer .footer__wrap .footer__blocks .footer__element li a:hover {
      color: #fcdb00; }
    .footer .footer__wrap .footer__blocks .footer__element .email {
      margin: 8px 0; }

@media (max-width: 776px) {
  .footer {
    margin-top: 100px; }
    .footer .footer__wrap .footer__title h2 {
      font-size: 26px; }
    .footer .footer__wrap .footer__title p {
      font-size: 16px; }
    .footer .footer__wrap .footer__blocks {
      margin: 30px 0 0 0;
      justify-content: center; }
      .footer .footer__wrap .footer__blocks .footer__element {
        padding: 15px 15px; } }

@media (max-width: 382px) {
  .footer .footer__wrap .footer__blocks .footer__element li a, .footer .footer__wrap .footer__blocks .footer__element li {
    font-size: 14px; } }

@media (max-width: 351px) {
  .footer .footer__wrap .footer__blocks .footer__element li a, .footer .footer__wrap .footer__blocks .footer__element li {
    font-size: 11px; } }
